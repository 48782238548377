import React from "react"
import { graphql } from "gatsby"
import BaseLayout from "../components/layout/base-layout"
import { ContentWrapper } from "@wonder-horse/shared"

const Support = ({ data }) => {
  const {
    markdownRemark: {
      html,
      frontmatter: { title },
    },
  } = data

  return (
    <BaseLayout title={title}>
      <ContentWrapper>
        <div className="w-full md:w-3/5 mb-5 md:mb-28">
          <h1 className="text-center mb-10">{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </ContentWrapper>
    </BaseLayout>
  )
}

export default Support

export const query = graphql`
  query SuppportQuery {
    markdownRemark(frontmatter: { name: { eq: "support" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
